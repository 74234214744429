import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { PAGE_TITLES, EXPERIENCE } from "../constants/constants";
import AOS from "aos";

const Experience = () => {
  const [visibleDots, setVisibleDots] = useState([false]);

  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        once: false,
        duration: 1000,
      });
    };

    initAOS();
    setVisibleDots(
      new Array(EXPERIENCE.length).fill(false).map((_, i) => i === 0)
    );

    // Call handleAnimationComplete for each index after AOS initialization
    EXPERIENCE.forEach((_, index) => {
      handleAnimationComplete(index);
    });
  }, []);

  const handleAnimationComplete = (index) => {
    setVisibleDots((prev) => {
      const newDots = [...prev];
      newDots[index + 1] = true;
      return newDots;
    });
    AOS.refresh();
  };

  return (
    <div
      className="min-h-screen bg-black overflow-hidden relative mb-20"
      id="experience"
    >
      {/* Component title */}
      <div className="text-center mb-16">
        <div
          className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03]"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {PAGE_TITLES.EXPERIENCE}
        </div>
      </div>

      <div className="container mx-auto px-4">
        {/* Updated grid container for mobile */}
        <div className="grid md:grid-cols-[1fr_60px_1fr] grid-cols-1 gap-4 relative">
          {/* Updated vertical line: hidden on mobile */}
          <div className="absolute left-1/2 top-0 bottom-0 w-0 border-l-2 border-yellow-500 border-dashed z-1 transform -translate-x-1/2 hidden md:block" />

          {EXPERIENCE.map((exp, index) => (
            <React.Fragment key={index}>
              {/* Left card container: grid col only on md */}
              <div className={index % 2 === 0 ? "" : "md:col-start-3"}>
                {index % 2 === 0 && (
                  <motion.div
                    className="bg-gradient-to-br from-[#191919] to-[#1a1a1a] rounded-xl p-6 shadow-xl duration-300 w-full md:max-w-[500px] mx-auto md:ms-auto border-r-4 border-yellow-500"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    onAnimationComplete={() => handleAnimationComplete(index)}
                  >
                    <div className="flex mb-4 mx-3">
                      <div className="my-auto">
                        <img
                          src={exp.company_logo}
                          alt={exp.company_name}
                          className="w-16 h-16 shadow-lg"
                        />
                      </div>
                      <div className="flex flex-col ms-4">
                        <h3 className="fs-4 font-semibold text-white text-start">
                          {exp.title}
                        </h3>
                        <a
                          href={exp.company_website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="fs-5 text-left text-yellow-400 font-semibold"
                        >
                          {exp.company_name}
                        </a>
                        <div className="text-left text-gray-300 text-sm">
                          {exp.duration}
                        </div>
                      </div>
                    </div>
                    {/* Role details - Bullet Points */}
                    {/* <ul className="text-gray-300 text-left list-disc list-inside m-2">
                      {Array.isArray(exp.role_details) &&
                        exp.role_details.map((detail, detailIndex) => (
                          <li key={detailIndex}>{detail}</li>
                        ))}
                    </ul> */}
                    {/* Skills - Chips */}
                    <div className="flex flex-wrap gap-2 mt-4">
                      {exp.skills.map((skill, skillIndex) => (
                        <span
                          key={skillIndex}
                          className="px-3 py-1 bg-[#e1ad07] text-black rounded-full text-sm shadow-md"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </motion.div>
                )}
              </div>

              {/* Center dot: hidden on mobile */}
              <div className="col-start-2 col-end-3 hidden md:flex justify-center items-center">
                {visibleDots[index + 1] && (
                  <div className="w-5 h-5 rounded-full bg-gradient-to-r from-yellow-300 to-yellow-500 animate-pulse" />
                )}
              </div>

              {/* Right card container: grid col only on md */}
              <div className={index % 2 === 1 ? "" : "md:col-start-1"}>
                {index % 2 === 1 && (
                  <motion.div
                    className="bg-gradient-to-br from-[#191919] to-[#1a1a1a] rounded-xl p-6 shadow-xl duration-300 w-full md:max-w-[500px] mx-auto md:me-auto border-l-4 border-yellow-500"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    onAnimationComplete={() => handleAnimationComplete(index)}
                  >
                    <div className="flex mb-4 mx-3">
                      <div className="my-auto">
                        <img
                          src={exp.company_logo}
                          alt={exp.company_name}
                          className="w-16 h-16 shadow-lg"
                        />
                      </div>
                      <div className="flex flex-col ms-4">
                        <h3 className="fs-4 font-semibold text-white text-start">
                          {exp.title}
                        </h3>
                        <a
                          href={exp.company_website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="fs-5 text-left text-yellow-400 font-semibold"
                        >
                          {exp.company_name}
                        </a>
                        <div className="text-left text-gray-300 text-sm">
                          {exp.duration}
                        </div>
                      </div>
                    </div>
                    {/* Role details - Bullet Points */}
                    {/* <ul className="text-gray-300 text-left list-disc list-inside m-2">
                      {Array.isArray(exp.role_details) &&
                        exp.role_details.map((detail, detailIndex) => (
                          <li key={detailIndex}>{detail}</li>
                        ))}
                    </ul> */}
                    {/* Skills - Chips */}
                    <div className="flex flex-wrap gap-2">
                      {exp.skills.map((skill, skillIndex) => (
                        <span
                          key={skillIndex}
                          className="px-3 py-1 bg-[#e1ad07] text-black rounded-full text-sm shadow-md"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </motion.div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
