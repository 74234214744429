import { useEffect } from "react";
import React from "react";
import { PAGE_TITLES, EDUCATION } from "../constants/constants";
import AOS from "aos";

const Education = () => {
  // Optimized AOS initialization
  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        once: false,
      });
    };

    initAOS();
  }, []);

  return (
    <div className="min-h-screen bg-black overflow-hidden" id="education">
      <div
        className="text-center text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] px-4 py-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {PAGE_TITLES.EDUCATION}
      </div>

        {EDUCATION.map((edu, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row my-5 justify-center items-center px-4"
            data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            {/* Left column - Education details */}
            <div className="flex flex-col mb-5 md:mb-0 md:me-5 w-full md:w-auto md:min-w-[300px] lg:min-w-[450px]">
              <div className="my-auto">
                {/* University row */}
                  <div className="flex flex-col"> 
                  <img
                      src={edu.logo}
                      alt={`${edu.university} logo`}
                      className="w-24 h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 rounded-full mx-auto mb-2"
                    />
                    <p className="text-base md:text-lg text-yellow-400 font-semibold text-center mb-2">
                      {edu.degree}
                    </p>
                    <h3 className="text-lg md:text-xl font-bold text-white text-center mb-2">
                      {edu.university}
                    </h3>
                    <p className="text-gray-400 text-center">{edu.location}</p>
                  </div>
              </div>
            </div>

            {/* Right column - Degree image with frame */}
            <img
              src={edu.degreePhoto}
              alt={`${edu.degree} certificate`}
              className="md:w-auto md:h-64 lg:h-72 object-contain"
            />
          </div>
        ))}
    </div>
  );
};

export default Education;
