import { memo, useEffect } from "react";
import React from "react";
import { PAGE_TITLES } from "../constants/constants";
import {
  faMeteor,
  faToolbox,
  faUserAstronaut,
  faStar,
  faGamepad,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TechStackIcon from "../components/TechStackIcon";
import AnimatedCounter from "../components/AnimatedCounter";
import { TECH_STACKS, NUMBER_STATS } from "../constants/constants";
import AOS from "aos";

const Header = memo(() => (
  <div className="text-center relative px-3 md:px-0">
    <div
      className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03]"
      data-aos="fade-left"
      data-aos-duration="600"
    >
      {PAGE_TITLES.ABOUT_ME}
    </div>
    <p
      className="mt-2 mb-4 mx-auto flex flex-row items-center justify-center wrap"
      data-aos="fade-right"
      data-aos-duration="800"
    >
      <span className="text-gray-400 fs-5">
        <FontAwesomeIcon icon={faMeteor} className="w-5 h-5 text-[#ffff03] me-2" />
        Transforming creativity into dynamic digital solutions
        <FontAwesomeIcon icon={faMeteor} className="w-5 h-5 text-[#ffff03] ms-2" />
      </span>
    </p>
  </div>
));

const AboutMe = () => {
  // Optimized AOS initialization
  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        once: false,
        mirror: true,
        duration: 1000,
      });
    };

    initAOS();
  }, []);

  return (
    <div className="min-h-screen bg-black overflow-hidden mb-20" id="about-me">
      <Header />
      {/* Changed container to responsive flex-col for mobile and flex-row for md and up */}
      <div className="flex flex-col md:flex-row my-3 justify-center gap-5 px-4">
        {/* Left column: updated flex and alignment classes */}
        <div className="flex flex-col items-start px-4">
          <div
            className="font-bold mb-3 d-flex"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <FontAwesomeIcon
              icon={faUserAstronaut}
              className="w-6 h-6 text-yellow-400 pt-1"
            />
            <div className="text-3xl mx-2 text-yellow-400">Introduction</div>
          </div>
          <div
            className="text-gray-300 text-lg text-left max-w-[700px]"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            I'm Jinesh Mehta, a experienced Machine Learning Engineer with 7+
            years of experience building large-scale AI solutions across
            healthcare, energy, and aerospace industries. My expertise includes
            developing large-scale AI solutions (Traditional ML and LLMs) across
            the full machine learning lifecycle. At companies like CVS Health,
            Schneider Electric, and Honeywell, I've consistently delivered AI
            solutions that drive measurable business impact through improved
            efficiency, reduced costs, and enhanced user experiences.
          </div>

          <div
            className="font-bold my-3 d-flex"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <FontAwesomeIcon
              icon={faStar}
              className="w-6 h-6 text-yellow-400 pt-1"
              d
            />
            <div className="text-3xl text-yellow-400 mx-2 text-start">
              What Sets Me Apart?
            </div>
          </div>
          <div
            className="text-gray-300 text-lg text-left max-w-[700px]"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            My strength lies in transforming complex AI concepts into enterprise
            solutions that deliver quantifiable business value. I excel at
            building complete systems—from model development to cloud
            infrastructure—that seamlessly integrate with business processes. My
            balanced approach combines technical innovation with practical
            implementation, consistently driving improvements in efficiency and
            user engagement while reducing operational costs.
          </div>

          <div
            className="font-bold my-3 d-flex"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <FontAwesomeIcon
              icon={faGamepad}
              className="w-6 h-6 text-yellow-400 pt-1"
            />
            <div className="text-3xl mx-2 text-yellow-400 text-start">
              What I Do When AFK?
            </div>
          </div>
          <div
            className="text-gray-300 text-lg text-left max-w-[700px]"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Beyond my technical work, I'm actively engaged in the machine
            learning community through knowledge sharing and collaborative
            initiatives. I participate in ML meetups and conferences, where I
            discuss emerging trends and challenges in AI implementation. In my
            personal time, I enjoy exploring outdoor trails, practicing
            mindfulness through meditation, and competing in strategic board
            games.
          </div>
        </div>

        {/* Right column: updated flex and alignment classes */}
        <div className="flex flex-col items-center px-4">
          {/* Tech Stack Title */}
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faToolbox}
              className="text-3xl text-yellow-400 me-3"
            />
            <span
              className="text-3xl font-bold text-yellow-400 flex items-center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Technical Skills
            </span>
          </div>

          {/* Tech Stack Icons: changed grid to be responsive */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mt-6">
            {TECH_STACKS.map((item, index) => (
              <div
                key={index}
                className="transform transition-all duration-300"
                data-aos={
                  index % 4 === 0
                    ? "fade-up-right"
                    : index % 4 === 1
                    ? "fade-up"
                    : index % 4 === 2
                    ? "fade-up-left"
                    : "fade-up"
                }
                data-aos-duration="1000"
                data-aos-delay={100 * (index % 8)}
              >
                <TechStackIcon Icon={item.icon} Stack={item.stack} />
              </div>
            ))}
          </div>

          {/* By the number Title */}
          <div className="flex items-center mt-8">
            <FontAwesomeIcon
              icon={faCalculator}
              className="text-3xl text-yellow-400 me-3"
            />
            <span
              className="text-3xl font-bold text-yellow-400 flex items-center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              By The Numbers
            </span>
          </div>

          {/* By The Numbers Stats: changed grid to one column on mobile, two on small screens */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-6">
            {NUMBER_STATS.map((stat, index) => (
              <div
                key={index}
                className="p-2 rounded-xl bg-gradient-to-br from-[#191919] to-[#1a1a1a] transition-all"
                data-aos={index % 2 === 0 ? "zoom-in-right" : "zoom-in-left"}
                data-aos-duration="800"
                data-aos-delay={100 * index}
              >
                <div className="flex flex-col">
                  <FontAwesomeIcon
                    icon={stat.icon}
                    className="h-7 w-7 text-yellow-400 mx-auto"
                  />
                  <div className="flex mx-auto">
                    <div className="fs-6 font-bold text-yellow-400 text-center ms-1">
                      {stat.title}
                    </div>
                  </div>
                  <div className="fs-4 text-gray-300 font-semibold text-center">
                    {(() => {
                      // Extract the numeric value
                      const numericValue = parseFloat(
                        stat.value.replace(/[^\d.]/g, "")
                      );

                      // Extract suffix (K, M, B, %)
                      let suffix = "";
                      if (stat.value.includes("B")) suffix += "B";
                      if (stat.value.includes("M")) suffix += "M";
                      if (stat.value.includes("K")) suffix += "K";
                      if (stat.value.includes("+")) suffix += "+";

                      return (
                        <AnimatedCounter
                          end={numericValue}
                          duration={2000}
                          suffix={suffix}
                        />
                      );
                    })()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
