import React from 'react';
import { COPYRIGHT_TEXT } from '../constants/constants';

const Footer = () => {
    return (
        <footer className="bg-black text-center py-3">
            <div className="container">
                <p className="mb-0">{COPYRIGHT_TEXT}</p>
            </div>
        </footer>
    );
};

export default Footer;