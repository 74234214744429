import React, { useEffect } from "react";
import { PAGE_TITLES, recommendationsData } from "../constants/constants";
import AOS from "aos";

const Recommendations = () => {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
    });
  }, []);

  return (
    <div className="min-h-screen bg-black overflow-hidden mb-16" id="recommendations">
      <div
        className="text-center px-4 py-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03]">
          {PAGE_TITLES.RECOMMENDATIONS}
        </h1>
      </div>
      <div className="container grid gap-8 sm:grid-cols-1 lg:grid-cols-2 p-4 md:p-2 lg:p-0">
        {recommendationsData.map((rec, index) => {
          const isLeftCard = index % 2 === 0;
          return (
            <div
              key={index}
              data-aos={isLeftCard ? "fade-right" : "fade-left"}
              data-aos-duration="1000"
              className={`bg-gradient-to-br from-[#191919] to-[#1a1a1a] p-4 rounded-lg flex flex-col ${
                isLeftCard ? "ml-0 lg:ml-[120px]" : "mr-0 lg:mr-[120px]"
              }`}
            >
              <div className="flex items-center px-3 pt-3">
                <img
                  src={rec.image_url}
                  alt={rec.name}
                  className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full border-2 border-yellow-500 shadow-lg transform transition-transform duration-300 hover:scale-105"
                />
                <div className="ml-4">
                  <div className="text-start text-lg md:text-xl font-bold text-yellow-400">
                    <a
                      href={rec.linkedin_url ? rec.linkedin_url : "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rec.name}
                      <i className="fab fa-linkedin ms-2 text-primary"></i>
                    </a>
                  </div>
                  <p className="text-start text-xs md:text-sm text-gray-400">
                    {rec.position}
                  </p>
                  <a href={rec.company_website ? rec.company_website : "#"} target="_blank" rel="noopener noreferrer">
                  <p className="text-start text-sm md:text-md font-semibold" style={{ color: `${rec.company_color}` }}>
                    {rec.company}
                  </p>
                  </a>
                </div>
                <div className="ml-auto flex items-center justify-center">
                  {rec.company_logo && (
                    <img
                      src={rec.company_logo}
                      alt={rec.company}
                      className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
                    />
                  )}
                </div>
              </div>
              <div className="flex px-4 pt-3">
                <div className="text-xl text-yellow-500 mr-4">
                  <i className="fas fa-quote-left"></i>
                </div>
                <p className="pt-3 text-gray-300 text-start">
                  {rec.message}
                </p>
                <div className="text-xl text-yellow-500 ml-4">
                  <i className="fas fa-quote-right"></i>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Recommendations;
