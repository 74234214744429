import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, useLocation} from 'react-router-dom';
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe';
import Research from './pages/Research';
import Experience from './pages/Experience';
import Education from './pages/Education';
import Recommendations from './pages/Recommendations';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import LogoSplash from './components/LogoScreen/LogoSplash';
import ContactMe from './pages/ContactMe';
import NotFound from './components/NotFound';

function AppContent() {
  const location = useLocation();
  
  // If path is not exactly "/", show the NotFound component
  if (location.pathname !== '/') {
    return (
      <>
      <Navbar isMinimal={true} />
      <NotFound />
      </>
    );
  }

  // Otherwise, show the main content
  return (
    <>
      <Navbar isMinimal={false} />
      <Home />
      <AboutMe />
      <Experience />
      <Recommendations />
      <Education />
      <Research />
      <ContactMe />
    </>
  );
}

function App() {
  const [loaded, setLoaded] = useState(false);

  // This useEffect hook is used to reset the scroll position when the user navigates to a new page.
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);



  return (
    <Router>
      <div className="App">
        {!loaded && <LogoSplash onLoaded={() => setLoaded(true)} />}
        {loaded && (
          <>
            <AppContent />
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
