import React, { useState, useEffect, useCallback, memo } from "react";
import {
  PAUSE_DURATION,
  ERASING_SPEED,
  TYPING_SPEED,
  POSITIONS,
  SOCIAL_LINKS,
} from "../../constants/constants";
import "./Home.css";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfilePicture from "../../components/ProfilePicture";
import { faFileText } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const splitWords = (text) => {
    const words = text.split(" ");
    const mid = Math.ceil(words.length / 2);
    return {
      topBlock: words.slice(0, mid).join(" "),
      bottomBlock: words.slice(mid).join(" "),
    };
  };

  const [text, setText] = useState({ topBlock: "", bottomBlock: "" });
  const [isTyping, setIsTyping] = useState(true);
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  // AOS initialization - needed for animations
  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        once: true,
        offset: 10,
      });
    };

    initAOS();
    window.addEventListener("resize", initAOS);
    return () => window.removeEventListener("resize", initAOS);
  }, []);

  // Optimize typing effect
  const handleTyping = useCallback(() => {
    const { topBlock, bottomBlock } = splitWords(POSITIONS[wordIndex]);
    const fullText = `${topBlock} ${bottomBlock}`;
    if (isTyping) {
      if (charIndex <= fullText.length) {
        setText((prev) => ({
          topBlock: fullText
            .slice(0, charIndex)
            .split(" ")
            .slice(0, topBlock.split(" ").length)
            .join(" "),
          bottomBlock: fullText
            .slice(0, charIndex)
            .split(" ")
            .slice(topBlock.split(" ").length)
            .join(" "),
        }));
        setCharIndex((prev) => prev + 1);
      } else {
        setTimeout(() => setIsTyping(false), PAUSE_DURATION);
      }
    } else {
      if (charIndex > 0) {
        setText((prev) => ({
          topBlock: fullText
            .slice(0, charIndex - 1)
            .split(" ")
            .slice(0, topBlock.split(" ").length)
            .join(" "),
          bottomBlock: fullText
            .slice(0, charIndex - 1)
            .split(" ")
            .slice(topBlock.split(" ").length)
            .join(" "),
        }));
        setCharIndex((prev) => prev - 1);
      } else {
        setWordIndex((prev) => (prev + 1) % POSITIONS.length);
        setIsTyping(true);
      }
    }
  }, [charIndex, isTyping, wordIndex]);

  // Typing Effect
  useEffect(() => {
    const timeout = setTimeout(
      handleTyping,
      isTyping ? TYPING_SPEED : ERASING_SPEED
    );
    return () => clearTimeout(timeout);
  }, [handleTyping]);

  const SocialIcons = () => {
    return (
      <div className="flex justify-center my-2 sm:my-4 gap-3 sm:gap-4">
        {SOCIAL_LINKS.map((social, index) => (
          <a
            key={index}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            className="transition-colors duration-300"
          >
            <FontAwesomeIcon
              className="hover:text-[#e1ad07] text-2xl sm:text-3xl md:text-4xl"
              icon={social.icon}
            />
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className="container min-h-screen bg-black overflow-hidden px-4 sm:px-6 pt-16 sm:pt-20 md:pt-24" id="home">
      <div className="flex flex-col lg:flex-row items-center justify-center min-h-[calc(100vh-88px)]">
        {/* Left side - Profile Picture */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center justify-center py-8 lg:py-0"
          data-aos="fade-right"
          data-aos-delay="600"
        >
          <div className="flex flex-col items-center justify-center h-full">
            <ProfilePicture />
          </div>
        </div>

        {/* Right side - Text Content */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center lg:items-start justify-center py-8 lg:py-0"
          data-aos="fade-left"
          data-aos-delay="600"
        >
          <div className="flex flex-col items-center lg:items-start justify-center w-full max-w-2xl mx-auto lg:mx-0 lg:pr-8">
            <div className="p-2 w-full text-center lg:text-left" data-aos="fade-up" data-aos-delay="600">
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">
                Hey! I'm{" "}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e1ad07] to-[#ffff03]">
                  Jinesh
                </span>
                . I am a
              </h1>
            </div>
            
            {/* Typing Effect */}
            <div className="p-2 w-full text-center lg:text-left" data-aos="fade-up" data-aos-delay="600">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold" style={{ minHeight: "120px", lineHeight: "1.2" }}>
                {/* Top Block */}
                <span className="relative inline-block">
                  <span className="relative bg-gradient-to-r from-yellow-100 via-yellow-200 to-yellow-400 bg-clip-text text-transparent">
                    {text.topBlock}
                  </span>
                </span>
                <br />
                {/* Bottom Block */}
                <span className="relative inline-block mt-2">
                  <span className="relative bg-gradient-to-l from-[#e1ad07] to-[#ffff03] bg-clip-text text-transparent">
                    {text.bottomBlock}
                  </span>
                </span>
              </h1>
            </div>

            {/* Description */}
            <div className="p-2 text-base sm:text-lg md:text-xl lg:text-2xl text-center lg:text-left" data-aos="fade-up" data-aos-delay="600">
              I bring industrial expertise in AI, specializing in end-to-end
              development of RAGs and LLMs, from research to scalable real-world
              deployment.
            </div>

            <div className="flex flex-col items-center w-full">
              <a
                href={require("../../assets/resume_jinesh_mehta.pdf")}
                download="resume_jinesh_mehta.pdf"
                className="my-4"
              >
                <button
                  data-aos="fade-up"
                  data-aos-duration="800"
                  className="mt-4 px-4 py-2 fs-5  bg-gradient-to-r from-[#e1ad07] to-[#ffff03] text-black font-bold rounded fs-5 transition-colors duration-300 hover:from-[#ffff03] hover:to-[#e1ad07]"
                >
                  <FontAwesomeIcon icon={faFileText} className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="ml-2">Download CV</span>
                </button>
              </a>

              {/* Social Links */}
              <div
                className="flex gap-3 sm:gap-4 justify-center w-full mt-2 sm:mt-4 mx-auto"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <SocialIcons />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Home);
