import { useState, useEffect, useRef } from 'react';

const AnimatedCounter = ({ end, duration = 2000, suffix = '' }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const animationStarted = useRef(false);
  const isVisibleRef = useRef(false);
  
  useEffect(() => {
    // Ensure end is a valid number
    const targetValue = parseInt(end) || 0;
    
    // Create intersection observer to detect when element enters or leaves viewport
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      
      if (entry.isIntersecting) {
        // Element has entered the viewport
        isVisibleRef.current = true;
        
        // Start animation if it hasn't already started
        if (!animationStarted.current) {
          startAnimation(targetValue, duration);
        }
      } else {
        // Element has left the viewport
        isVisibleRef.current = false;
        
        // Reset animation state so it can run again when element comes back into view
        animationStarted.current = false;
        setCount(0);
      }
    }, { threshold: 0.1 });
    
    // Function to handle the animation
    const startAnimation = (target, animDuration) => {
      animationStarted.current = true;
      
      // Reset count to 0 when starting animation
      setCount(0);
      
      let startTime = null;
      
      const animateCount = (timestamp) => {
        // If element is no longer visible, stop animation
        if (!isVisibleRef.current) return;
        
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / animDuration, 1);
        
        // Easing function for smoother animation
        const easeOutQuad = progress * (2 - progress);
        
        // Set the current count
        const currentValue = Math.floor(easeOutQuad * target);
        setCount(currentValue);
        
        // Continue animation until complete
        if (progress < 1 && isVisibleRef.current) {
          requestAnimationFrame(animateCount);
        } else {
          // Ensure final value is exactly the target
          setCount(target);
        }
      };
      
      // Start animation
      requestAnimationFrame(animateCount);
    };
    
    // Start observing
    if (counterRef.current) {
      observer.observe(counterRef.current);
    }
    
    return () => {
      observer.disconnect();
    };
  }, [end, duration]);
  
  return (
    <span className="counter-value" ref={counterRef}>
      {count}{suffix}
    </span>
  );
};

export default AnimatedCounter;
