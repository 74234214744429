import React, { useState, memo } from "react";

const ProfilePicture = memo(() => {
  const [showVideo, setShowVideo] = useState(false);
  const [isRotating, setIsRotating] = useState(false);

  const handleToggle = () => {
    setIsRotating(true);
    setTimeout(() => {
      setShowVideo((prev) => !prev);
      setIsRotating(false);
    }, 250); // Half of the transition duration
  };

  return (
    <div className="flex flex-col justify-end items-center p-2 py-2 pb-2">
      <div 
        className={`m-3 w-64 h-64 xs:w-72 xs:h-72 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:w-[400px] lg:h-[400px] 
        rounded-full overflow-hidden transition-transform duration-500 ${isRotating ? "rotate-180" : ""}`}
      >
        {showVideo || isRotating ? (
          <video
            src={require("./../assets/genai_portrait.mp4")}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover pan-top"
          />
        ) : (
          <img
            src={require("./../assets/profile.png")}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        )}
      </div>
      <button
        onClick={handleToggle}
        className="mt-4 px-4 py-2 fs-5 
        bg-gradient-to-r from-[#e1ad07] to-[#ffff03] text-black font-bold rounded 
        transition-colors duration-300 hover:from-[#ffff03] hover:to-[#e1ad07]"
      >
        {showVideo ? "Show the Real Me!" : "Show GenAI Live Portrait"}
      </button>
    </div>
  );
});

export default ProfilePicture;
