import {
  faLinkedin,
  faGithub,
  faOrcid,
} from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGoogleScholar } from "@fortawesome/free-brands-svg-icons";

// Import SVG files directly - add these at the top of your file
import pythonIcon from '../assets/skills/python.svg';
import tensorflowIcon from '../assets/skills/tensorflow.svg';
import pytorchIcon from '../assets/skills/pytorch.svg';
import mysqlIcon from '../assets/skills/mysql.svg';
import mongodbIcon from '../assets/skills/mongodb.svg';
import fastapiIcon from '../assets/skills/fastapi.svg';
import awsIcon from '../assets/skills/aws.svg';
import gcpIcon from '../assets/skills/gcp.svg';
import dockerIcon from '../assets/skills/docker.svg';
import kubernetesIcon from '../assets/skills/kubernetes.svg';
import javaIcon from '../assets/skills/java.svg';
import kafkaIcon from '../assets/skills/kafka.svg';
import sparkIcon from '../assets/skills/spark.svg';
import javascriptIcon from '../assets/skills/javascript.svg';
import reactIcon from '../assets/skills/react.svg';
import nodejsIcon from '../assets/skills/nodejs.svg';
import mitIcon from '../assets/education/mit.svg';
import camiIcon from '../assets/experience/cami.svg';
import cvsIcon from '../assets/experience/cvs.svg';
import seIcon from '../assets/experience/se.svg';
import honeywellIcon from '../assets/experience/honeywell.svg';
import rilIcon from '../assets/experience/ril.svg';
import neuIcon from '../assets/education/neu.svg';
import { faCode, faDatabase, faRocket, faUser } from "@fortawesome/free-solid-svg-icons";

export const recommendationsData = [
  {
    name: "Sai Vineeth K R",
    position: "Senior Machine Learning Engineer",
    company: "Schneider Electric",
    company_color: "#009e4d",
    company_logo: seIcon,
    company_website: "https://www.se.com",
    linkedin_url: "https://www.linkedin.com/in/sai-vineeth-k-r-b1802499/",
    image_url: "https://media.licdn.com/dms/image/v2/D4E03AQFB9pULQWBfsg/profile-displayphoto-shrink_400_400/B4EZVn0ZuxGwAk-/0/1741203545365?e=1747267200&v=beta&t=oDjEvj5zhuzF-zk4O75W_dkTBH7ZsTc8wietShkqOZ8",
    message:
      "Jinesh demonstrated exceptional technical skills and leadership during his time with the Innovation and Architecture team at Schneider Electric. He played a key role in developing a recommendation engine using reinforcement learning, proactively exploring and applying various approaches to improve performance. Jinesh showcased adaptability by working seamlessly with AWS services, quickly mastering complex tools and techniques. He collaborated effectively with the team, ensuring timely delivery of high-quality results while handling challenges independently. His strong problem-solving mindset, combined with his ability to learn rapidly and contribute meaningfully, makes him an excellent fit for machine learning and data science roles.",
  },
  {
    name: "Santhosh Kumar Jagadish",
    position: "Head of Technical Product Management",
    company: "Schneider Electric",
    company_color: "#009e4d",
    company_logo: seIcon,
    company_website: "https://www.se.com",
    linkedin_url: "https://www.linkedin.com/in/santhosh-jagadish/",
    image_url: "https://media.licdn.com/dms/image/v2/C4D03AQHLK8nntFkLKg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1649724661646?e=1747267200&v=beta&t=G5ZB7ALflySAyS8q7nmG9PIzabNfL2o4k7rf0vr9cv4",
    message:
      "Jinesh worked at SE under my leadership, developing machine learning solutions on AWS. He built recommendation engines leveraging reinforcement learning techniques. He demonstrated a proactive mindset and a strong eagerness to learn new methods. Jinesh explored diverse approaches to optimize recommendation models and developed a solid understanding of various machine learning concepts. During his time at SE, he independently learned complex AWS services to design and build end-to-end ML pipelines. He consistently showed initiative, took ownership of his work, and drove projects to successful outcomes. I highly recommend Jinesh for roles in Machine Learning or Data Science.",
  },
  {
    name: "Karthikeyan Balachandran",
    position: "Mech Engr Assistant Manager",
    company: "Honeywell",
    company_color: "#de2034",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    linkedin_url: "https://www.linkedin.com/in/karthikeyan-balachandran-95293618b/",
    image_url: "https://media.licdn.com/dms/image/v2/C5603AQHBQ3IW3xFgPA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1658911922093?e=1747267200&v=beta&t=oroFwYnVcGebplM0TaojVhHlciRY_K5Pju3AikoXDxw",
    message:
      "Jinesh consistently demonstrated a strong work ethic, effective problem-solving skills, and clear communication, contributing significantly to our team's success. He collaborated seamlessly across departments, fostering a positive and productive work environment. His adaptability and eagerness to embrace new challenges made him an invaluable asset in dynamic settings. Jinesh also displayed leadership potential by taking on additional responsibilities and mentoring newer team members. With his results-driven mindset and commitment to excellence, he consistently delivered high-quality outcomes. I have no doubt that Jinesh will excel in any future endeavors he pursues, and I wholeheartedly recommend him for any role in Machine Learning.",
  },
  {
    name: "Dustin Brandt",
    position: "Senior Engineering Manager",
    company: "Honeywell",
    company_color: "#de2034",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    linkedin_url: "https://www.linkedin.com/in/dustin-brandt-22a65916/",
    image_url: "https://media.licdn.com/dms/image/v2/C4D03AQHfYuoKt__c6A/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516604680625?e=1747267200&v=beta&t=d1MxcBWQPcb9Ngrn2YST7aoRV0L7gi8Gu30Sswu-xs8",
    message:
      "In my role as combustion methods focal at Honeywell Aerospace I have coordinated several small in-house code development efforts and have had the pleasure to work alongside a multitude of very talented individuals. Jinesh certainly stands out first in his overall knowledge of commonly used computer science paradigms and his zeal for learning more. We worked together on an in-house code based on dependency-based resolution of tasks purposed for post processing of computational fluid dynamics simulations. In this role he mentored junior engineers helping them successfully complete. Due to his unique capabilities his time was difficult to obtain as he was sought out by a number of other programs. He was able to handle several tasks at once.",
  },
  {
    name: "Bao Nguyen",
    position: "Principal Mechanical Design Engineer",
    company: "Honeywell",
    company_color: "#de2034",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    linkedin_url: "https://www.linkedin.com/in/bao-nguyen-362689186/",
    image_url: "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg",
    message:
      "Jinesh is a hard working and valued contributor to our project. His deep knowledge in software application is evident and his suggestions to resolve our issues are well thought out and all prove to be successful. I appreciate his help and highly recommend Jinesh for whichever endeavors he decides to pursue.",
  },
  {
    name: "Mahesh Singh",
    position: "Project Lead",
    company: "Honeywell",
    company_color: "#de2034",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    linkedin_url: "https://www.linkedin.com/in/mahesh-singh-34210615/",
    image_url: "https://media.licdn.com/dms/image/v2/C4D03AQEQLsAp36QTdg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516552944687?e=1747267200&v=beta&t=kFVGasBbi6njJbTfeCLP78Z-2dz86PixjvHUcX13CkQ",
    message:
      "Jinesh demonstrated exceptional technical leadership, driving key projects from development to delivery while collaborating with global teams and US-based stakeholders. He built software tools in C++, Qt, and Python for aircraft engine design and developed a high-performance engine parts database supporting concurrent users. He led the design and implementation of CI/CD pipelines, promoted test-driven development to improve quality, and introduced technologies like Wix to enhance scalability and portability. Jinesh effectively managed cross-functional teams, ensuring alignment with US stakeholders, meeting project goals, and accelerating development timelines. His leadership, combined with strong communication and problem-solving skills, fostered collaboration and consistently delivered high-impact results.",
  },
  {
    name: "James Ayers",
    position: "Staff Engineer (Eng VI)",
    company: "Honeywell",
    company_color: "#de2034",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    linkedin_url: "https://www.linkedin.com/in/jamesayers3rd/",
    image_url: "https://media.licdn.com/dms/image/v2/D5603AQHOGo35kGNwvA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1664383189244?e=1747267200&v=beta&t=T2J1ryvsjqO6Cnd6F1ifqk-FkMAr209w8pJ0nsBnIrM",
    message:
      "Jinesh showed significant hard work and ability to grasp new concepts quickly. He implemented a complex coding program that enables productivity. Jinesh would be a welcome addition to any graduate program and highly recommend him.",
  },
  {
    name: "Vinayak Mathur",
    position: "Member of Scientific Staff",
    company: "CAMI - MIT University",
    company_color: "#da4208",
    company_logo: mitIcon,
    company_website: "https://www.manipal.edu",
    linkedin_url: "https://www.linkedin.com/in/vin101/",
    image_url: "https://media.licdn.com/dms/image/v2/D4D03AQGVvinUGReuGg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1710305529803?e=1747267200&v=beta&t=uwVs3c6yZ6ash6-iWCmNNrBrvi7UgBoHUERcHUG9uPM",
    message:
      "Jinesh is an exceptional machine learning engineer with a proven track record of technical innovation and leadership. At the Center for Artificial and Machine Intelligence (CAMI), he developed a multi-model real-time emotion detection system that achieved an impressive 74% accuracy across six emotion categories — showcasing his ability to translate complex concepts into practical, high-performing solutions. He also spearheaded the integration of event-driven computing with AWS Cloud, leading to significantly faster web application execution times. Beyond his technical achievements, Jinesh’s leadership and foresight were instrumental in driving these projects forward. His collaborative mindset, combined with his passion for AI and machine learning, made him a cornerstone of the team. With research published at prestigious venues like NeurIPS and a strong academic and hands-on background, Jinesh is a rare talent who brings both innovation and execution to any organization — making him an invaluable addition to any forward-thinking team.",
  },
  {
    name: "Sanjay Singh",
    position: "Coordinator",
    company: "CAMI - MIT University",
    company_color: "#da4208",
    company_logo: mitIcon,
    company_website: "https://www.manipal.edu",
    linkedin_url: "https://www.linkedin.com/in/sanjaysinghict/",
    image_url: "https://media.licdn.com/dms/image/v2/C4D03AQEq-XcjTE__Hw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516571740251?e=1747267200&v=beta&t=8lhhYtLDT4yF6DzBv7GU4DuMR14xW9X1dM7QcF__4bU",
    message:
      "Jinesh is an exceptional computer vision engineer who combines technical expertise with strong leadership and problem-solving skills. He led innovative projects applying convolutional neural networks to facial detection and recognition, enhancing existing clustering algorithms like DBSCAN through creative approaches. His research efforts resulted in publications at top conferences, including NeurIPS and ICCCSP, showcasing his ability to deliver high-impact work. Beyond his technical capabilities, Jinesh’s excellent communication skills and collaborative mindset made him a respected team member, fostering productive teamwork. His adaptability, self-motivation, and commitment to continuous learning make him a valuable asset to any AI or engineering team.",
  },
  
];

export const PAGE_TITLES = {
  HOME: "Welcome to My Personal Website",
  ABOUT_ME: "Who Am I",
  PROJECTS: "My Projects",
  EXPERIENCE: "What's my Impact",
  EDUCATION: "Whatever I've Learned",
  RESEARCH: "Everything I've Discovered",
  CONTACT_ME: "Say Hello!",
  RECOMMENDATIONS: "Why People Love Me", 
};

export const NAV_ITEMS = [
  {
    id: "home",
    section_name: "Home",
  },
  {
    id: "about-me",
    section_name: "About Me",
  },
  {
    id: "experience",
    section_name: "Experience",
  },
  {
    id: "recommendations",
    section_name: "Testimonials",
  },
  {
    id: "education",
    section_name: "Education",
  },
  {
    id: "research",
    section_name: "Research",
  },
  {
    id: "contact-me",
    section_name: "Contact Me",
  },
];

export const POSITIONS = [
  "Machine Learning Engineer",
  "Data Scientist",
  "Software Developer",
];
export const TYPING_SPEED = 100;
export const ERASING_SPEED = 50;
export const PAUSE_DURATION = 2000;

export const SOCIAL_LINKS = [
  {
    icon: faGithub,
    link: "https://github.com/mehtajinesh/",
    name: "Github",
    displayName: "Github",
    subText: "@mehtajinesh",
    color: "#ffffff",
    gradient: "from-[#333] to-[#24292e]",
  },
  {
    icon: faLinkedin,
    link: "https://www.linkedin.com/in/mehtajineshs/",
    name: "LinkedIn",
    displayName: "LinkedIn",
    subText: "@mehtajineshs",
    gradient: "from-[#0A66C2] to-[#0077B5]",
    color: "#0A66C2",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/astute_indian/?hl=en",
    name: "Instagram",
    displayName: "Instagram",
    subText: "@astute_indian",
    color: "#E4405F",
    gradient: "from-[#833AB4] via-[#E4405F] to-[#FCAF45]",
  },
  {
    icon: faGoogleScholar,
    link: "https://scholar.google.co.in/citations?user=3jgyae4AAAAJ&hl=en",
    name: "Scholar",
    displayName: "Scholar",
    subText: "Jinesh Mehta",
    color: "#ffffff",
    gradient: "from-[#4285F4] to-[#34A853]",
  },
  {
    icon: faOrcid,
    link: "https://orcid.org/0000-0001-8932-1406",
    name: "ORCID",
    displayName: "ORCID",
    subText: "0000-0001-8932-1406",
    color: "rgb(174,205, 84)",
    gradient: "from-[#A6CE39] to-[#A6CE39]",
  },
];

export const TECH_STACKS = [
  {
    stack: "Python",
    icon: pythonIcon,
  },
  {
    stack: "Tensorflow",
    icon: tensorflowIcon,
  },
  {
    stack: "PyTorch",
    icon: pytorchIcon,
  },
  {
    stack: "MySQL",
    icon: mysqlIcon,
  },
  {
    stack: "MongoDB",
    icon: mongodbIcon,
  },
  {
    stack: "FastAPI",
    icon: fastapiIcon,
  },
  {
    stack: "AWS",
    icon: awsIcon,
  },
  {
    stack: "GCP",
    icon: gcpIcon,
  },
  {
    stack: "Docker",
    icon: dockerIcon,
  },
  {
    stack: "Kubernetes",
    icon: kubernetesIcon,
  },
  {
    stack: "Java",
    icon: javaIcon,
  },
  {
    stack: "Kafka",
    icon: kafkaIcon,
  },
  {
    stack: "Spark",
    icon: sparkIcon,
  },
  // {
  //   stack: "HTML5",
  //   icon: html5Icon,
  // },
  {
    stack: "JavaScript",
    icon: javascriptIcon,
  },
  // { 
  //   stack: "CSS3", 
  //   icon: css3Icon 
  // },
  // { 
  //   stack: "Bootstrap",
  //   icon: bootstrapIcon
  // },
  {
    stack: "React",
    icon: reactIcon,
  },
  {
    stack: "Node.js",
    icon: nodejsIcon,
  },
  // {
  //   stack: "Git",
  //   icon: gitIcon,
  // },
];

export const NUMBER_STATS = [
  {
    "title": "ML Models Deployed",
    "icon": faRocket,
    "value": "700+",
  },
  {
    "title": "Lines of Code",
    "icon": faCode,
    "value": "100K+",
  },
  {
    "title": "Data Points Processed",
    "icon": faDatabase,
    "value": "100M+",
  },
  {
    "title": "End Users Impacted",
    "icon": faUser,
    "value": "10M+",
  }
];  

export const EXPERIENCE = [
  {
    title: "Machine Learning Engineer",
    company_name: "CVS Health",
    company_logo: cvsIcon,
    company_website: "https://www.cvs.com",
    duration: "Dec 2023 - Current",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["Python", "SQL", "Tensorflow", "FastAPI", "Docker", "BigQuery", "Vertex AI", "PyTorch", "Kubernetes"]
  },
  {
    title: "Machine Learning Engineer",
    company_name: "Schneider Electric",
    company_logo: seIcon,
    company_website: "https://www.se.com",
    duration: "July 2022 - Dec 2022",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["Python", "Tensorflow", "SQL", "Pandas", "Django", "AWS Lambda", "AWS SageMaker", "Kubernetes"],
  },
  {
    title: "Research Associate",
    company_name: "Northeastern University",
    company_logo: neuIcon,
    company_website: "https://www.neu.edu",
    duration: "Jan 2023 - Nov 2023",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["Python", "Scikit-Learn", "NumPy", "Kubernetes", "MongoDB"],
  },
  {
    title: "Senior ML Engineer",
    company_name: "Honeywell",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    duration: "Oct 2019 - July 2021",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["Python", "PyTorch", "SQL", "React", "JavaScript", "NodeJS", "MongoDB"],
  },
  {
    title: "ML Engineer",
    company_name: "Honeywell",
    company_logo: honeywellIcon,
    company_website: "https://www.honeywell.com",
    duration: "July 2017 - Sept 2019",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["C++", "Tensorflow", "SQL", "Python", "AWS SageMaker"],
  },
  {
    title: "Member of Technical Staff",
    company_name: "CAMI - MIT University",
    company_logo: camiIcon,
    company_website: "https://www.manipal.edu",
    duration: "Jan 2016 - June 2017",
    role_details: [
      "Built machine learning models to predict customer behavior and preferences.",
      "Optimized algorithms for better performance.",
      "Collaborated with data engineers to ensure data quality.",
    ],
    skills: ["Python", "PyTorch", "SQL", "React", "JavaScript", "Kubernetes" ],
  },
  // {
  //   title: "Software Developer Intern",
  //   company_name: "Fracktal Works",
  //   company_logo: require("../assets/experience/fracktalworks.jpg"),
  //   company_website: "https://fracktal.in/",
  //   duration: "June 2016 - July 2016",
  //   role_details: [
  //     "Analyzed data to identify trends and patterns for business insights.",
  //     "Developed data visualization tools to present findings.",
  //     "Worked with stakeholders to understand data needs.",
  //   ],
  //   skills: ["Python", "Java", "SQL", "JavaScript"],
  // },
  {
    title: "Software Developer Intern",
    company_name: "Reliance Industries Limited",
    company_logo: rilIcon,
    company_website: "https://www.ril.com/",
    duration: "June 2015 - July 2015",
    role_details: [
      "Developed software and network solutions to improve business processes and efficiency.",
      "Collaborated with cross-functional teams to gather requirements and deliver solutions.",
      "Implemented security measures to protect sensitive data.",
    ],
    skills: ["Python", "C++", "Network Security"],
  },
];

export const EDUCATION = [
  {
    degree: "Master of Science in Computer Science",
    university: "Northeastern University",
    location: "Boston, MA",
    logo: neuIcon,
    degreePhoto: require("../assets/education/neu_degree.png"),
    courses: [
      "Machine Learning",
      "Deep Learning",
      "Data Mining",
      "Computer Vision",
      "Natural Language Processing",
    ],
  },
  {
    degree: "Bachelor of Technology in Computer Science",
    university: "Manipal Institute of Technology",
    location: "Manipal, India",
    logo: mitIcon,
    degreePhoto: require("../assets/education/mit_degree.png"),
    courses: [
      "Data Structures",
      "Algorithms",
      "Operating Systems",
      "Computer Networks",
      "Database Management Systems",
    ],
  },
];

export const RESEARCH_PAPERS = [
  {
    title:
      "HCA-DBSCAN: HyperCube accelerated density based spatial clustering for applications with noise",
    authors: ["Vinayak Mathur", "Jinesh Mehta", "Sanjay Singh"],
    publication: "Conference on Neural Information Processing Systems",
    year: "2019",
    conference_link: "https://neurips.cc/",
    link: "https://arxiv.org/abs/1912.00323",
    logo: require("../assets/research/neurips.png"),
  },
  {
    title: "Face detection and tagging using deep learning",
    authors: ["Jinesh Mehta", "Eshaan Ramnani", "Sanjay Singh"],
    publication:
      "International Conference on Computer, Communication, and Signal Processing (ICCCSP)",
    year: "2018",
    conference_link: "https://icccsp.com",
    link: "https://ieeexplore.ieee.org/abstract/document/8452853",
    logo: require("../assets/research/ieee.png"),
  },
  {
    title:
      "Pothole Detection and Analysis System (PoDAS) for Real Time Data Using Sensor Networks",
    authors: [
      "Jinesh Mehta",
      "Vinayak Mathur",
      "Dhruv Agarwal",
      "Atish Sharma",
      "Krishna Prakasha",
    ],
    publication: "Journal of Engineering and Applied Sciences",
    year: "2017",
    conference_link: "https://jeas.springeropen.com/",
    link: "https://researcher.manipal.edu/en/publications/pothole-detection-and-analysis-system-podas-for-real-time-data-us/fingerprints/",
    logo: require("../assets/research/springer.png"),
  },
];

export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} Jinesh Mehta™. All Rights Reserved.`;
