import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCommentDots,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    Swal.fire({
      title: "Pushing Message...",
      html: "Please wait while I make a note of your message",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      // Get form data
      const form = e.target;
      const formData = new FormData(form);

      // Submit form
      await form.submit();

      // Show success message
      Swal.fire({
        title: "Success!",
        text: "Your message has been sent successfully!",
        icon: "success",
        confirmButtonColor: "#6366f1",
        timer: 2000,
        timerProgressBar: true,
      });

      // Reset form
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong. Please try again later.",
        icon: "error",
        confirmButtonColor: "#6366f1",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div data-aos="fade-right" data-aos-duration="1200" className="bg-white/10 rounded rounded-4 backdrop-blur transition-all duration-300 p-4">
      <div className="fs-4 font-bold text-center text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] mb-2">
        Drop a Message!
      </div>
      <div className="text-center text-gray-400 mb-2">
        Have something to discuss? Send me a message and let's talk.
      </div>

      <form action="https://formsubmit.co/mehtajineshs@gmail.com" method="POST" onSubmit={handleSubmit} className="mt-4">
        {/* FormSubmit Configuration */}
        <input type="hidden" name="_template" value="table" />
        <input type="hidden" name="_captcha" value="false" />

        <div data-aos="fade-up" data-aos-delay="100" className="relative group my-4">
          <FontAwesomeIcon
            icon={faUser}
            className="absolute left-4 top-3 w-5 h-5 text-gray-400 group-focus-within:text-[#e1ad07] transition-colors"
          />
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            disabled={isSubmitting}
            className="w-full px-5 py-2 bg-white/10 rounded-xl placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#e1ad07]/30 transition-all duration-300 hover:border-[#e1ad07]/30 disabled:opacity-50"
            required
          />
        </div>
        <div data-aos="fade-up" data-aos-delay="200" className="relative group my-4">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="absolute left-4 top-3 w-5 h-5 text-gray-400 group-focus-within:text-[#e1ad07] transition-colors"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            disabled={isSubmitting}
            className="w-full px-5 py-2 bg-white/10 rounded-xl placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#e1ad07]/30 transition-all duration-300 hover:border-[#e1ad07]/30 disabled:opacity-50"
            required
          />
        </div>
        <div data-aos="fade-up" data-aos-delay="300" className="relative group my-4">
          <FontAwesomeIcon
            icon={faCommentDots}
            className="absolute left-4 top-3 w-5 h-5 text-gray-400 group-focus-within:text-[#e1ad07] transition-colors"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            disabled={isSubmitting}
            className="w-full px-5 py-2 bg-white/10 rounded-xl placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#e1ad07]/30 transition-all duration-300 hover:border-[#e1ad07]/30 h-[11.9rem] disabled:opacity-50"
            required
          />
        </div>
        <button
          data-aos="fade-up"
          data-aos-delay="400"
          type="submit"
          disabled={isSubmitting}
          className="bg-gradient-to-r from-[#e1ad07] to-[#ffff03] text-black rounded-xl transition-all duration-300 px-6 py-3 mx-auto space-x-2"
        >
          <FontAwesomeIcon icon={faPaperPlane} className="w-5 h-5" />
          <span>{isSubmitting ? "Sending..." : "Send Message"}</span>
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
