import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import loadingWeb from "./../../assets/loading.gif";
import "./LogoSplash.css";
import AOS from "aos";

const TypewriterEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      if (index <= text.length) {
        setDisplayText(text.slice(0, index));
        index++;
      } else {
        clearInterval(timer);
      }
    }, 240);

    return () => clearInterval(timer);
  }, [text]);

  return (
    <span className="inline-block">
      {displayText}
      <span className="animate-pulse">|</span>
    </span>
  );
};

const LogoSplash = ({ onLoaded }) => {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: false,
    });

    // Handle window resize for responsiveness
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoading(false);
        if (typeof onLoaded === "function") {
          onLoaded();
        }
      }, 1000);
    }, 5000);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [onLoaded]);

  // defining variants for framer-motion
  // containerVariants is for the parent container
  const containerVariants = {
    exit: {
      opacity: 0,
      scale: 1.1,
      filter: "blur(10px)",
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  // childVariants is for the children elements
  const childVariants = {
    exit: {
      y: -20,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
  };

  // if loading is false, return null
  if (!loading) return null;

  return (
    <div
      className={`logo-splash ${fadeOut ? "fade-out" : ""}`}
    >
      <AnimatePresence>
        <motion.div className="fixed inset-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit="exit"
          variants={containerVariants}
        >
          <div className="relative min-h-screen flex items-center justify-center px-2 sm:px-4 md:px-6">
              <div className="w-full max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-4xl mx-auto">
                
                <img
                  src={`${loadingWeb}?${new Date().getTime()}`}
                  alt="Loading"
                  className="logo-splash-image mx-auto w-1/2 sm:w-2/5 md:w-1/3 lg:w-1/4 transition-all duration-300"
                />

                {/* Welcome Text */}
                <motion.div
                  className="text-center my-4 sm:mb-6 md:mb-8 lg:mb-12"
                  variants={childVariants}
                >
                  <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-6xl font-bold space-y-1 sm:space-y-2 md:space-y-4">
                    <div className="flex flex-col sm:block">
                      <span
                        data-aos="fade-right"
                        data-aos-delay="500"
                        className="inline-block px-1 sm:px-2 bg-gradient-to-r from-white via-yellow-100 to-yellow-200 bg-clip-text text-transparent mb-1 sm:mb-0"
                      >
                        Hey There!
                      </span>
                      <span
                        data-aos="fade-right"
                        data-aos-delay="1000"
                        className="inline-block px-1 sm:px-2 bg-warning bg-clip-text text-transparent mb-1 sm:mb-0"
                      >
                        I am Jinesh.
                      </span>
                      <span
                        data-aos="fade-right"
                        data-aos-delay="1500"
                        className="inline-block px-1 sm:px-2 bg-gradient-to-r from-white via-yellow-100 to-yellow-200 bg-clip-text text-transparent mb-1 sm:mb-0"
                      >
                       Welcome to my world,
                      </span>
                      <span
                        data-aos="fade-right"
                        data-aos-delay="2000"
                        className="inline-block px-1 sm:px-2 bg-gradient-to-r from-white via-yellow-100 to-yellow-200 bg-clip-text text-transparent mb-1 sm:mb-0"
                      >
                        which is powered by
                      </span>
                      <span
                        data-aos="fade-right"
                        data-aos-delay="2500"
                        className="inline-block px-1 sm:px-2 bg-gradient-to-r from-white via-yellow-100 to-yellow-200 bg-clip-text text-transparent mb-1 sm:mb-0"
                      >
                        creativity, data, and a passion for
                      </span>
                      <span
                        data-aos="fade-right"
                        data-aos-delay="3000"
                        className="inline-block px-1 sm:px-2 bg-warning bg-clip-text text-transparent"
                      >
                      AI.
                      </span>
                    </div>
                  </h1>
                </motion.div>
                <motion.div
                  className="text-center mt-4 sm:mt-6"
                  variants={childVariants}
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <a
                    href="https://www.jineshmehta.com"
                    className="inline-flex items-center gap-1 sm:gap-2 px-3 py-2 sm:px-4 sm:py-2 md:px-6 md:py-3 rounded-full relative group hover:scale-105 transition-transform duration-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-yellow-600/20 to-yellow-600/20 rounded-full blur-md group-hover:blur-lg transition-all duration-300" />
                    <div className="relative flex items-center gap-1 sm:gap-2 text-sm sm:text-lg md:text-xl lg:text-2xl">
                      <div className="fa-solid fa-globe me-2 w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 text-yellow-600" />
                      <span className="bg-gradient-to-r from-yellow-600 to-yellow-600 bg-clip-text text-transparent">
                        <TypewriterEffect text="jineshmehta.com" />
                      </span>
                    </div>
                  </a>
                </motion.div>
              </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default LogoSplash;
