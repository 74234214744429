import React from 'react';

const TechStackIcon = ({ Icon, Stack }) => {
  return (
    <div className="p-2 rounded-xl bg-gradient-to-br from-[#191919] to-[#1a1a1a] flex flex-col">
        <img 
          src={Icon} 
          alt={`${Stack} icon`} 
          className="h-8 w-8 mb-2 mx-auto"
        />
      <span className="text-sm text-gray-300 font-semibold text-center">
        {Stack}
      </span>
    </div>
  );
};

export default TechStackIcon; 