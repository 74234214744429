import React from "react";

import { useEffect } from "react";
import AOS from "aos";
import Social from "../components/Social";
import ContactForm from "../components/ContactForm";
import { PAGE_TITLES } from "../constants/constants";

const ContactMe = () => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  return (
    <div className="flex bg-black" id="contact-me">
      <div className="container px-4">
        <div className="d-flex flex-column text-center">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-center mx-auto text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] text-4xl md:text-6xl"
          >
            {PAGE_TITLES.CONTACT_ME}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1100"
            className="d-flex flex-row mx-auto text-gray-400 fs-5 mt-2 px-4 text-sm md:text-base"
          >
            Wanna Talk? Drop a message and I will follow up as soon as I get
            that.
          </div>
        </div>

        <div className="flex flex-col md:flex-row m-2 md:m-3 justify-center items-center md:items-start gap-5 p-3 md:p-5">
          <Social />
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
