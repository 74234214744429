import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NAV_ITEMS } from "./../../constants/constants";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ isMinimal = false }) => {
  const [activeSection, setActiveSection] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Add body lock when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen]);

  useEffect(() => {
    // Skip setting up scroll event handlers if in minimal mode
    if (isMinimal) return;
    
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
      const sections = NAV_ITEMS.map((item) => {
        const section = document.querySelector(`#${item.id}`);
        if (section) {
          return {
            id: item.id,
            offset: section.offsetTop - 550,
            height: section.offsetHeight,
          };
        }
        return null;
      }).filter(Boolean);

      const currentPosition = window.scrollY;
      const active = sections.find(
        (section) =>
          currentPosition >= section.offset &&
          currentPosition < section.offset + section.height
      );

      if (active) {
        setActiveSection(active.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMinimal]);

  const scrollToSection = (e, id) => {
    e.preventDefault();
    // If minimal mode, don't scroll
    if (isMinimal) return;
    
    const section = document.querySelector(`#${id}`);
    if (section) {
      const top = section.offsetTop - 100;
      window.scrollTo({
        top: top,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };

  return (
    <>
      <nav
        className={`fixed top-0 left-0 w-full z-40 py-2 transition-all duration-300 ${
          scrolled && !menuOpen ? "bg-black/50 backdrop-blur-xl shadow-lg" : "bg-black"
        }`}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Mobile Header */}
          <div className="flex items-center justify-between lg:hidden">
            {/* Logo */}
            <Link
              className="text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] text-xl sm:text-2xl font-bold z-50"
              onClick={(e) => scrollToSection(e, "home")}
            >
              Jinesh Mehta
            </Link>
            
            {/* Mobile Menu Button - only show if not in minimal mode */}
            {!isMinimal && (
              <button 
                className="text-white focus:outline-none z-50"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <FontAwesomeIcon 
                  icon={menuOpen ? faTimes : faBars} 
                  className="text-2xl transition-transform duration-300" 
                />
              </button>
            )}
          </div>

          {/* Desktop Navigation - Centered */}
          <div className="items-center hidden lg:flex">
            {/* Logo - Left Side */}
            <Link
              className="text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] text-2xl font-bold me-auto"
              onClick={(e) => scrollToSection(e, "home")}
            >
              Jinesh Mehta
            </Link>
            
            {/* Only show navigation and contact items if not in minimal mode */}
            {!isMinimal && (
              <>
                <div className="flex space-x-4 mx-auto">
                  {/* Navigation Items - Center */}
                  {NAV_ITEMS.filter(
                    (item) => item.section_name !== "Contact Me"
                  ).map((item, index) => (
                    <Link
                      key={index}
                      className={`text-md text-white hover:text-[#e1ad07] transition-colors duration-200 relative py-2 px-1 ${
                        activeSection === item.id ? "active font-bold" : ""
                      }`}
                      onClick={(e) => scrollToSection(e, item.id)}
                    >
                      {item.section_name}
                      {activeSection === item.id && (
                        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#e1ad07] to-[#ffff03]"></span>
                      )}
                    </Link>
                  ))}
                </div>
                {/* Contact Button - Right Side */}
                {NAV_ITEMS.filter(
                  (item) => item.section_name === "Contact Me"
                ).map((item, index) => (
                  <Link
                    key={index}
                    className="inline-block font-semibold text-black bg-gradient-to-r from-[#e1ad07] to-[#ffff03] px-2 py-1 rounded transition-transform duration-200 hover:scale-105 ms-auto"
                    onClick={(e) => scrollToSection(e, item.id)}
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />{item.section_name}
                  </Link>
                ))}
              </>
            )}
          </div>
        </div>
      </nav>

      {/* Full-screen Mobile Navigation Overlay - only show if not in minimal mode */}
      {!isMinimal && (
        <div 
          className={`fixed inset-0 bg-black/95 backdrop-blur-lg z-30 lg:hidden flex flex-col overflow-auto transition-all duration-300 ease-in-out pt-16 ${
            menuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
          }`}
        >
          <div className="container mx-auto px-4 py-6 min-h-full flex flex-col">
            {/* Add spacing at the top to prevent hiding under navbar */}
            <div className="h-2"></div>
            
            <ul className="flex flex-col space-y-4 items-center justify-start my-4 max-h-[70vh] overflow-y-auto">
              {NAV_ITEMS.filter(
                (item) => item.section_name !== "Contact Me"
              ).map((item, index) => (
                <li key={index} className="w-full max-w-xs">
                  <Link
                    className={`block py-2.5 px-3 text-white text-center text-base sm:text-lg hover:bg-white/10 rounded-lg transition-all duration-200 transform ${
                      activeSection === item.id 
                        ? "font-bold bg-gradient-to-r from-[#e1ad07]/20 to-[#ffff03]/20 scale-105" 
                        : ""
                    }`}
                    onClick={(e) => scrollToSection(e, item.id)}
                  >
                    {item.section_name}
                    {activeSection === item.id && (
                      <span className="block w-10 h-0.5 mx-auto mt-1.5 bg-gradient-to-r from-[#e1ad07] to-[#ffff03] rounded-full"></span>
                    )}
                  </Link>
                </li>
              ))}
              {NAV_ITEMS.filter(
                (item) => item.section_name === "Contact Me"
              ).map((item, index) => (
                <li key={index} className="mt-2 w-full max-w-xs">
                  <Link
                    className="block w-full text-center text-black bg-gradient-to-r from-[#e1ad07] to-[#ffff03] px-4 py-2.5 rounded-lg font-medium text-base sm:text-lg"
                    onClick={(e) => scrollToSection(e, item.id)}
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />{item.section_name}
                  </Link>
                </li>
              ))}
            </ul>
            
            <div className="mt-auto pb-6 text-center text-white/50 text-xs sm:text-sm">
              Tap anywhere to close
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
