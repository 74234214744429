import { useEffect, useState } from "react";
import React from "react";
import { PAGE_TITLES, RESEARCH_PAPERS } from "../constants/constants";
import AOS from "aos";

const Research = () => {
  // State to track hovered card
  const [hoveredCard, setHoveredCard] = useState(null);
  // State to detect mobile view
  const [isMobile, setIsMobile] = useState(false);

  // Listen for viewport changes
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Optimized AOS initialization
  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        once: false,
        duration: 600,
      });
    };

    initAOS();
    
  }, [hoveredCard]);

  return (
    <div className="min-h-screen overflow-hidden" id="research">
      {/* Research Section Title */}
      <div
        className="pb-3 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03]"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {PAGE_TITLES.RESEARCH}
      </div>
      {/* Research Paper Cards */}
      <div className="flex flex-wrap justify-center items-start mt-10">
        {RESEARCH_PAPERS.map((paper, index) => {
          // Determine if card is "active" (hovered or low-res view)
          const active = isMobile || hoveredCard === index;
          return (
            <div
              key={index}
              className={`bg-gradient-to-br from-[#191919] to-[#1a1a1a] m-4 max-w-xs w-full rounded-xl shadow-lg transform transition duration-500 flex-shrink-0 self-start relative ${
                active ? 'hover-active' : ''
              }`}
              style={{ 
                height: active ? 'auto' : '300px',
                alignSelf: 'flex-start'
              }}
              onMouseEnter={() => !isMobile && setHoveredCard(index)}
              onMouseLeave={() => !isMobile && setHoveredCard(null)}
            >
              <div className="flex flex-col h-full">
                {/* Research Paper Logo */}
                <div className={`${!active ? 'h-full' : ''}`}>
                  <img
                    src={paper.logo}
                    className={`${active ? 'rounded-t-xl' : 'rounded-xl'} transition-all duration-500`}
                    alt={paper.publication}
                  />
                </div>
                
                {/* Hover for Details - bouncing indicator below the image (hidden on mobile) */}
                {!isMobile && !active && (
                  <div className="text-center mt-5 mb-2">
                    <div className="text-yellow-400 font-medium animate-bounce py-1 px-3 inline-block rounded-lg">
                      Hover for Details
                    </div>
                  </div>
                )}
                
                {/* Research Paper Details - Always rendered but conditionally animated */}
                <div 
                  className={`text-center p-4 transition-all duration-500 overflow-hidden ${
                    active ? 'max-h-100 opacity-100' : 'max-h-0 opacity-0 p-0'
                  }`}
                  data-aos={active ? "fade-up" : ""}
                  data-aos-duration="400"
                >
                  <h3 
                    className="text-xl font-semibold text-white"
                    data-aos={active ? "fade-down" : ""}
                    data-aos-delay="100"
                  >
                    {paper.title}
                  </h3>
                  <a
                    href={paper.conference_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg text-yellow-400 font-semibold block mt-2"
                    data-aos={active ? "fade-down" : ""}
                    data-aos-delay="150"
                  >
                    {paper.publication}
                  </a>
                  <div 
                    className="text-gray-400 text-sm mt-2"
                    data-aos={active ? "fade-down" : ""}
                    data-aos-delay="200"
                  >
                    {paper.year}
                  </div>
                  <a
                    href={paper.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn mt-4 bg-gradient-to-r from-yellow-500 to-yellow-300 text-black font-bold py-2 px-4 rounded-lg transition"
                    data-aos={active ? "fade-down" : ""}
                    data-aos-delay="250"
                  >
                    Read Paper
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Research;
