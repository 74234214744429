import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SOCIAL_LINKS } from "../constants/constants";

const Social = () => {
  return (
    <div 
      className="rounded-lg backdrop-blur bg-white/10 transition-all duration-300 p-3 sm:p-4"
      data-aos="fade-left" 
      data-aos-duration="1200"
    >
      <div className="text-lg sm:text-xl font-bold text-center mb-3 mx-auto text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03]">
        Socialize with Me
      </div>
      
      <div className="rounded p-1 sm:p-3">
        <div className="flex flex-col gap-2 sm:gap-4">
          {SOCIAL_LINKS.map((social, index) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center bg-gradient-to-r ${social.gradient} 
                backdrop-blur rounded px-2 sm:px-3 py-1.5 sm:py-2 gap-2 sm:gap-3 transition-all duration-500 
                hover:scale-[1.02] active:scale-[0.98]`}
            >
              <div className="flex justify-center items-center flex-shrink-0">
                <FontAwesomeIcon 
                  icon={social.icon}
                  className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 transition-colors"
                />
              </div>
              <div className="flex flex-col text-left overflow-hidden">
                <span className="text-sm sm:text-base md:text-lg text-white font-medium transition-colors truncate">
                  {social.displayName}
                </span>
                <span className="text-xs sm:text-sm text-gray-200 transition-colors truncate">
                  {social.subText}
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Social;