import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import pageNotFound from "./../assets/404.gif";

const NotFound = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

return (
    <div className="min-h-screen flex justify-center items-center px-4 py-8 bg-black">
        {/* Main content section */}
        <div className="flex flex-col items-center justify-center max-w-5xl w-full gap-6 sm:gap-10 lg:gap-14">
            {/* Animation section */}
            <div data-aos="fade-up" className="w-full sm:w-2/3 md:w-1/2 lg:w-2/5 flex justify-center">
                <img
                    src={`${pageNotFound}?${new Date().getTime()}`}
                    alt="Page Not Found"
                    className="w-full max-w-xs sm:max-w-sm md:max-w-md transition-all duration-300"
                />
            </div>

            {/* Text section */}
            <div data-aos="fade-up" data-aos-delay="150" className="w-full text-center">
                <h2 className="text-2xl sm:text-3xl md:text-4xl py-2 font-bold text-transparent bg-clip-text bg-gradient-to-l from-[#e1ad07] to-[#ffff03] mb-2">This page doesn't exist</h2>
                <p className="text-sm sm:text-base md:text-lg text-gray-400">Please check your URL or return back to my home page</p>
            </div>

            {/* Button section */}
            <div data-aos="fade-up" data-aos-delay="300" className="w-full flex justify-center mt-2 sm:mt-4">
                <Link 
                    to="/" 
                    className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-to-r from-yellow-500 to-yellow-300 text-black font-bold text-sm sm:text-base rounded-lg transition-all duration-300 hover:scale-105"
                >
                    Go to Home Page
                </Link>
            </div>
        </div>
    </div>
);
};

export default NotFound;
